import {
  Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import * as bootstrap from 'bootstrap'
import Header from '@/components/Header.vue'
import { orderFactory } from '@/http/order'
import { userFactory } from '@/http/user'
import { masterDataFactory } from '@/http/master-data'
import Utils from '@/utils/utils'
import Datepicker from 'vue3-datepicker'
import constants from '@/constants/constants'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

@Options({
  components: {
    Header,
    Datepicker,
    VPagination
  },
  data() {
    return {
      orders: [],
      order: [],
      student: [],
      billUserInstallments: [],
      orderStatuses: {
        "0":"Semua Status",
        "310":"Aktif",
        "320":"Belum Bayar",
        "330":"Terbayar",
        "331":"Manual",
        "340":"Dibatalkan",
        "341":"Kedaluwarsa"
      },
      param: {
        status: null,
        date_from: null,
        date_to: null,
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: ""
      },
      schoolYears: [],
      months: [],
      data_master: {
        school_years: "1",
        months: "1",
      },
      totalPaid: 0,
      totalBill: 0,
      detailReady: false,
      currentPage: 1,
      totalPage: 1,
      searchField:"",
      modal:false,
      students: [],
      constants: constants,
      file: '',
      import_result: {
        rejected_orders: [],
        saved_orders: [],
      }
    }
  },
  watch: {
    '$route.query': {
      handler: 'onPageQueryChange',
      deep: true
    }
  },
  methods: {
    setRouter: function () {
      this.$router.push({
        path: '/payment',
        query: {
          child_user_id: this.param.child_user_id,
          page: this.currentPage,
        }
      })
    },
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    async getSuggestions(){
      if (this.searchField.length > 3) {
        var param = {
          "keyword": this.searchField
        }
        Promise.resolve(userFactory.students(param))
        .then(result => {
            this.students = result.data.users[0]
            this.students.forEach((value:any) => {
              if (value.child_name != null) {
                value.name = value.child_name
                value.user_id = value.child_user_id
              }
            })
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.students = []
        })
      } else {
        this.students = []
      }
    },
    setSearch(search:any){
      this.searchField = search.name
      this.param.child_user_id = search.user_id
      this.modal = false
      this.setRouter()
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
          month = '0' + month
      if (day.length < 2)
          day = '0' + day
      return [year, month, day].join('-')
    },
    orderDetail: function(order_id:any) {
      this.detailReady = false
      Promise.all([
        orderFactory.detailOrder(order_id)
      ]).then(results => {
        this.order = results[0].data.order
        this.student = results[0].data.student
        this.billUserInstallments = results[0].data.bill_user_installments
        this.detailReady = true
        this.showModal()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return
    },
    exportOrder: function() {
      var param = {
        status: this.param.status,
        date_from: null,
        date_to: null,
        keyword: this.param.keyword,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: this.param.child_user_id
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(orderFactory.exportOrder(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    templateImport: function() {
      var param = {}
      Promise.resolve(orderFactory.templateImport(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    showImportModal: function() {
      this.import_result.rejected_orders = []
      this.import_result.saved_orders = []
      this.importModal.show()
    },
    importOrder: function () {
      if (this.file == '') {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData();
      formData.append('excel', this.file);
      Promise.resolve(orderFactory.importOrder(formData))
        .then((response) => {
          if (response.data.rejected_orders) {
            this.import_result.rejected_orders = response.data.rejected_orders
          }
          if (response.data.saved_orders) {
            this.import_result.saved_orders = response.data.saved_orders
          }
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }

      if (!this.param.child_user_id) {
        var child_user_id = this.$route.query.child_user_id
        this.param.child_user_id = child_user_id
      }
      /* if (this.searchField.length == 0) {
        this.param.child_user_id = ''
      } */

      var param = {
        status: this.param.status,
        date_from: "",
        date_to: "",
        keyword: this.param.keyword,
        offset: this.param.offset,
        limit: this.param.limit,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: this.param.child_user_id
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      this.totalPaid  = 0
      Promise.all([
        orderFactory.orders(param)
      ]).then(results => {
        this.orders = results[0].data.orders
        this.totalBill = results[0].data.total_rows
        this.orders.forEach((value:any) => {
          if (value.status == constants.ORDER_SUCCESS) {
            this.totalPaid += value.total_payment
          }
        })
        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalBill + limit - 1) / limit)
        if ((totalPage * limit - this.totalBill) >= limit) {
          totalPage = Math.round(this.totalBill / limit)
        }
        this.totalPage = totalPage

        if (!this.searchField && this.param.child_user_id) {
          if (this.orders[0].student) {
            this.searchField = this.orders[0].student.name
          }
        }

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.all([
        masterDataFactory.master_datas(this.data_master)
      ]).then(results => {
        this.schoolYears = results[0].data.school_years
        this.months = results[0].data.months
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
            this.school_year_name = school_year.name
          }
        })
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    showModal: function() {
      this.myModal.show()
    },
    hideModal: function() {
      this.myModal.hide()
    }
  },
  async mounted () {
    await this.loadDataMaster()

    var myModal = document.getElementById('orderModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }

    var importModal = document.getElementById('importModal')
    if (importModal != null) {
      this.importModal = new bootstrap.Modal(importModal)
    }
  },
})

export default class Payment extends Vue {}

import axios from 'axios'

class UserFactory {

  async students(data:any) {
    try {
      let url = `/student/search`
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

}


export let userFactory = new UserFactory()
